/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, Button, Paper } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ApiService from "../../../services/app.service";
import SelectDatasource from "./SelectDatasource";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import { useSearchParams } from "react-router-dom";

const Comparative = ({ source1, source2, setSource1, setSource2 }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const firstDataSourceType = searchParams.get("firstDataSourceType");
  const secondDataSourceType = searchParams.get("secondDataSourceType");

  const [filesOptions, setFilesOptions] = useState([]);
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [firstDatasourceLoading, setFirstDatasourceLoading] = useState(false);
  const [secondDatasourceLoading, setSecondDatasourceLoading] = useState(false);

  const fetchConnectionByType = async (type) => {
    try {
      const response = await ApiService.getConnectionsByType({ type });
      if (type === "Database") {
        setDatabaseOptions(response?.data?.data);
      } else {
        setFilesOptions(response?.data?.data);
      }
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setFirstDatasourceLoading(true);
      setSecondDatasourceLoading(true);
      await fetchConnectionByType(firstDataSourceType === "database" ? "Database" : "Files");
      firstDataSourceType !== secondDataSourceType &&
        (await fetchConnectionByType(secondDataSourceType === "database" ? "Database" : "Files"));
      setFirstDatasourceLoading(false);
      setSecondDatasourceLoading(false);
    };
    fetchData();
  }, []);

  const updateDataSourceType = (newSourceType, sourceTypeName) => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set(sourceTypeName, newSourceType);
      return updatedParams;
    });
  };

  const handleNext = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isCustomQuery", false);
      newState.set("isPrivate", true);
      newState.set("firstSourceOrderBy", "");
      newState.set("secondSourceOrderBy", "");
      newState.set("isFullScan", false);
      newState.set("firstSourceConId", source1?.id);
      newState.set("secondSourceConId", source2?.id);
      newState.set("firstSourceConType", source1?.connectionType);
      newState.set("secondSourceConType", source2?.connectionType);

      return newState;
    });
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid spacing={2} container>
        <SelectDatasource
          heading={t("1st Data Source")}
          filesOptions={filesOptions}
          databaseOptions={databaseOptions}
          setSourceOption={setSource1}
          loading={firstDatasourceLoading}
          dataSourceType={firstDataSourceType}
          onChangeDataSourceType={(newType) => updateDataSourceType(newType, "firstDataSourceType")}
          setLoading={setFirstDatasourceLoading}
          fetchConnectionByType={fetchConnectionByType}
        />
        <SelectDatasource
          heading={t("2nd Data Source")}
          filesOptions={filesOptions}
          databaseOptions={databaseOptions}
          setSourceOption={setSource2}
          loading={secondDatasourceLoading}
          dataSourceType={secondDataSourceType}
          onChangeDataSourceType={(newType) => updateDataSourceType(newType, "secondDataSourceType")}
          setLoading={setSecondDatasourceLoading}
          fetchConnectionByType={fetchConnectionByType}
        />
      </Grid>
      <Box className="v-center" mt={3}>
        <Button sx={{ ml: "auto" }} disabled={!(source1 && source2)} variant="contained" onClick={handleNext}>
          {t("Next")}
        </Button>
      </Box>
    </Paper>
  );
};

export default Comparative;
