import { Box, Typography, Grid, Card, CardActionArea, CardContent, CardMedia } from "@mui/material";

const ConnectionCard = ({ eachCon, fetchConnectionData }) => {
  // const isCardDisabled = eachCon?.tablesCount === 0;
  return (
    <Card sx={{ height: "auto" }}>
      <CardActionArea
        // sx={{ opacity: isCardDisabled ? 0.5 : 1 }}
        // disabled={isCardDisabled}
        onClick={() => fetchConnectionData(eachCon)}>
        <CardContent sx={{ flex: "auto", display: "flex" }}>
          <Box sx={{ textOverflow: "ellipsis" }}>
            <Typography variant="subtitle1" color="text.secondary" fontSize={14} sx={{ textOverflow: "ellipsis" }}>
              Data Source Name : <strong>{eachCon?.connectionName}</strong>
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              No. of Tables : <strong>{eachCon?.tablesCount}</strong>
            </Typography>
          </Box>

          <CardMedia
            sx={{ width: 50, height: 50, ml: "auto" }}
            component="img"
            image={`/assets/DataInsights/${eachCon?.connectionType?.toLowerCase()}.png`}
            alt={eachCon?.connectionType}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const ConnectionsList = ({ connections, selectedDataSource, fetchConnectionData }) => {
  return (
    <Grid container spacing={2}>
      {connections
        ?.filter((each) => each?.connectionType?.includes(selectedDataSource))
        ?.map((eachCon, i) => (
          <Grid item md={4} key={i}>
            <ConnectionCard eachCon={eachCon} fetchConnectionData={fetchConnectionData} />
          </Grid>
        ))}
    </Grid>
  );
};

export default ConnectionsList;
