import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, FormHelperText, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../../App";
import APIServices from "../../../services/app.service";
import InnerHeader from "../../Layout/InnerHeader";
import CreateOrUpdateDQRule from "../CreateOrUpdateDQRule";
import ValidateResultModal from "../ValidateResultModal";
import Comparative from "./Comparative";
import DatabaseToFile from "./DatabaseToFile";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import { isAzure } from "../../../_helpers/Constant";
const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "50vw",
  },
});
const RowComparison = () => {
  const scrollRef = useRef();
  const [searchParams] = useSearchParams();
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [source1, setSource1] = useState(null);
  const [source2, setSource2] = useState(null);
  const [finalValidation, setFinalValidation] = useState([]);
  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(false);

  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const isPrivate = JSON.parse(searchParams.get("isPrivate"));
  const isFullScan = JSON.parse(searchParams.get("isFullScan"));
  const firstSourceOrderBy = searchParams.get("firstSourceOrderBy");
  const secondSourceOrderBy = searchParams.get("secondSourceOrderBy");
  const firstSourceConId = searchParams.get("firstSourceConId");
  const secondSourceConId = searchParams.get("secondSourceConId");
  const firstSourceConType = searchParams.get("firstSourceConType");
  const secondSourceConType = searchParams.get("secondSourceConType");
  const firstDSFileName = searchParams.get("firstDSFileName");
  const secondDSFileName = searchParams.get("secondDSFileName");
  const ruleId = searchParams.get("ruleId");
  const dataFormat = (ruleName) => {
    const formatData = {
      TestDescription: "Test Description comes here",
      TestType: "Comparison",
      comparissonValidations: [],
      TestName: ruleName,
      firstSourceOrderBy: firstSourceOrderBy || "",
      secondSourceOrderBy: secondSourceOrderBy || "",
      FirstDatasourceId: source1.id,
      FirstDatasourceName: source1?.dataBase || source1?.fileName || source1?.catalogName || source1.connectionName,
      firstDSFileName,
      FirstDatasourceType: source1?.fileName || isAzure(firstSourceConType) ? "File" : "Database",
      SecondDatasourceId: source2.id,
      SecondDatasourceName:
        source2?.dataBase || source2?.fileName || source2?.catalogName || secondDSFileName || source2.connectionName,
      SecondDatasourceType: source2?.fileName || isAzure(secondSourceConType) ? "File" : "Database",
    };

    finalValidation.forEach((obj) => {
      if (obj.validation.row_count_matching === true) {
        obj.source1.forEach((obj1, index) => {
          formatData.comparissonValidations.push({
            ValidationName: "RowCount",
            ValidationId: "21",
            ValidationDisplayName: "Row Count Match",
            FirstDataSource: [
              {
                Table: obj1.Table || obj1.filename,
                Column: obj1.Column,
                IsKey: obj1.IsKey,
              },
            ],
            SecondDataSource: [
              {
                Table: obj.source2[index].Table || obj.source2[index].filename,
                Column: obj.source2[index].Column,
                IsKey: obj.source2[index].IsKey,
              },
            ],
          });
        });
      }

      if (obj.validation.row_data_matching === true) {
        obj?.source1?.forEach((obj1, index) => {
          formatData.comparissonValidations.push({
            ValidationName: "RowComparison",
            ValidationId: "22",
            ValidationDisplayName: "Row Comparison",
            FirstDataSource: [
              {
                Table: obj1.Table || obj1.filename,
                Column: obj1.Column,
                IsKey: obj1.IsKey,
              },
            ],
            SecondDataSource: [
              {
                Table: obj.source2[index].Table || obj.source2[index].filename,
                Column: obj.source2[index].Column,
                IsKey: obj.source2[index].IsKey,
              },
            ],
          });
        });
      }
    });

    return formatData;
  };

  const sqlDataFormat = (ruleName) => {
    const formatData = {
      TestDescription: "Test Description comes here",
      TestType: "Comparison",
      comparissonValidations: [],
      TestName: ruleName,
      FirstDatasourceId: source1?.connectionName ? source1.id : source1._id,
      FirstDatasourceName: source1?.dataBase || source1?.fileName || source1?.catalogName || source1.connectionName,
      FirstDatasourceType: source1?.fileName ? "File" : "Database",
      SecondDatasourceId: source2.id,
      SecondDatasourceName: source2?.dataBase || source2?.fileName || source2?.catalogName || source2.connectionName,
      SecondDatasourceType: source2?.fileName ? "File" : "Database",
    };

    finalValidation.forEach((obj) => {
      if (obj.validation.row_count_matching === true) {
        obj.source1.forEach((obj1, index) => {
          formatData.comparissonValidations.push({
            SqlQuery: {
              FirstDataSource: obj1.sqlQuery,
              SecondDataSource: obj.source2[index].sqlQuery,
            },
            ValidationName: "RowCount",
            ValidationId: "21",
            ValidationDisplayName: "Row Count Match",
            FirstDataSource: [
              {
                Table: obj1.Table || obj1?.filename,
                Column: obj1.Column,
                IsKey: obj1.IsKey,
              },
            ],
            SecondDataSource: [
              {
                Table: obj.source2[index]?.Table || obj.source2[index]?.filename,
                Column: obj.source2[index].Column,
                IsKey: obj.source2[index].IsKey,
              },
            ],
          });
        });
      }

      if (obj.validation.row_data_matching === true) {
        obj?.source1?.forEach((obj1, index) => {
          formatData.comparissonValidations.push({
            SqlQuery: {
              FirstDataSource: obj1.sqlQuery,
              SecondDataSource: obj.source2[index].sqlQuery,
            },
            ValidationName: "RowComparison",
            ValidationId: "22",
            ValidationDisplayName: "Row Comparison",
            FirstDataSource: [
              {
                Table: obj1.Table || obj1.filename,
                Column: obj1.Column,
                IsKey: obj1.IsKey,
              },
            ],
            SecondDataSource: [
              {
                Table: obj.source2[index].Table || obj.source2[index].filename,
                Column: obj.source2[index].Column,
                IsKey: obj.source2[index].IsKey,
              },
            ],
          });
        });
      }
    });

    return formatData;
  };

  const createOrUpdateValidation = async (ruleName, createNew) => {
    setCreateLoading(true);
    let data;
    if (isCustomQuery) {
      data = sqlDataFormat(ruleName);
    } else {
      data = dataFormat(ruleName);
    }
    try {
      if (ruleId && !createNew) {
        await APIServices.updateValidation({ ...data, isFullScan: isFullScan }, ruleId);
      } else {
        await APIServices.createValidation({
          ...data,
          isPrivate: isPrivate,
          isFullScan: isFullScan,
        });
      }

      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message || error.message,
        open: true,
        colour: "error",
      });
    }
    setCreateLoading(false);
  };

  const checkValidation = async () => {
    setValidateLoading(true);
    let data;

    if (isCustomQuery) {
      data = sqlDataFormat("");
    } else {
      data = dataFormat("");
    }

    try {
      const response = await APIServices.checkValidation({
        ...data,
        isFullScan: isFullScan,
      });

      setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
      setValidationsResultShow(true);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setValidateLoading(false);
    }
  };

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const handleBack = () => {
    if (ruleId || !firstSourceConId) {
      navigate("/DataQualityRule");
    } else {
      navigate("/DataQualityRule/row-comparison/create?firstDataSourceType=database&secondDataSourceType=database");
      finalValidation?.length && setFinalValidation([]);
    }
  };

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between">
          <Box className="v-center" gap={2}>
            <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
            <Typography variant="h6">{t("createComparisionHeading")}</Typography>
          </Box>
          <IconButton>
            <NoMaxWidthTooltip width={"100%"} title={t("note9")} placement="left-start" arrow>
              <InfoOutlinedIcon />
            </NoMaxWidthTooltip>
          </IconButton>
        </Box>
      </InnerHeader>

      <Box className="comparisonContainer pt74" ref={scrollRef}>
        {!(ruleId || (firstSourceConId && secondSourceConId)) ? (
          <Comparative source1={source1} source2={source2} setSource1={setSource1} setSource2={setSource2} />
        ) : (
          <DatabaseToFile
            source1={source1}
            source2={source2}
            setSource1={setSource1}
            setSource2={setSource2}
            finalValidation={finalValidation}
            setFinalValidation={setFinalValidation}
            setSourcesLoading={setSourcesLoading}
            autoScroll={autoScroll}
          />
        )}

        {finalValidation?.length > 0 && firstSourceConId && !sourcesLoading && (
          <Grid container pt={2}>
            <Grid item sm={12} sx={{ textAlign: "right" }}>
              {validationsResultShow && (
                <ValidateResultModal
                  Validations={validationsResult}
                  model={true}
                  Comparative={true}
                  returnValue={(value) => {
                    setValidationsResultShow(value);
                    setValidationsResult([]);
                  }}
                  validationDetailsRowComparison={dataFormat}
                />
              )}
              <Button
                sx={{ mr: 2 }}
                color="error"
                size="small"
                variant="outlined"
                onClick={() => navigate("/DataQualityRule")}>
                {t("Cancel")}
              </Button>

              <CustomLoadingButton
                onClick={() => checkValidation()}
                sx={{ mr: 2 }}
                className="PreviewButton"
                disabled={validateLoading || createLoading}
                loading={validateLoading}
                startIcon={<></>}
                loadingPosition="start">
                {t("Preview")}
              </CustomLoadingButton>
              <CreateOrUpdateDQRule
                createLoading={createLoading}
                validateLoading={validateLoading}
                createValidation={createOrUpdateValidation}
              />
            </Grid>

            <Grid item xs={12}>
              <FormHelperText>{t("Note")} : </FormHelperText>
              <FormHelperText>{t("note5")}</FormHelperText>
              <FormHelperText>
                <FormHelperText>{t("note6")}</FormHelperText>
              </FormHelperText>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default RowComparison;
